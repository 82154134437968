
import { IonPage, IonContent, IonButton, IonCheckbox } from "@ionic/vue";
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import settingsApp from "@/libs//settings";
import { useGlobalFunctions } from "@/libs/globalFunctions";
import httpApp from "@/libs/http";
import { useStore } from "@/store";
import AccountNavComponent from "./AccountNavComponent.vue";
import MainHeader from "@/components/MainHeader.vue";
import MainFooter from "@/components/MainFooter.vue";

export default defineComponent({
  name: "Order Return",
  setup() {
    const { t, te } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const storeX = useStore();
    const { createLoading, openToast } = useGlobalFunctions();
    const orderNo = ref(route.params.orderNo);
    const currentOrder = ref(null);
    const currentOrderItems = ref([]);
    const returnable = ref(false);
    const addingRequest = ref(false);
    const orderReturnResons = ref();

    const returnData = ref({
      order_number: orderNo.value,
      returnreason_id: 0,
      resonError: false,
    });
    const returnItemsData = ref({});

    const isOrderReturnable = () => {
      httpApp
        .sendPostRequest(settingsApp.getEndpointUrl("checkReturnableOrder"), {
          order_id: currentOrder.value.entity_id,
        })
        .then(async (response: any) => {
          if (response.success) {
            returnable.value = response.is_returnable;
            orderReturnResons.value = response.return_resons;
          }
        })
        .catch((error) => {
          console.log(error, "error");
        });
    };

    const checkItemReturnable = async (item) => {
      httpApp
        .sendPostRequest(
          settingsApp.getEndpointUrl("checkReturnableOrderItem"),
          {
            order_id: currentOrder.value.entity_id,
            item_id: item.item_id,
          }
        )
        .then(async (response: any) => {
          if (response.success) {
            item.is_returnable = response.is_returnable;
            item.return_resons = response.return_resons;
          }
          currentOrderItems.value.push(item);
          returnItemsData.value[item.item_id] = {
            return_qty: 0,
            sku: item.sku,
            return_reason: 0,
            qtyError: false,
            resonError: false,
          };
        })
        .catch((error) => {
          console.log(error, "error");
        });
    };

    const getOrder = () => {
      httpApp
        .sendPostRequest(settingsApp.getEndpointUrl("getOrder"), {
          order_no: orderNo.value,
        })
        .then(async (response: any) => {
          if (!Object.prototype.hasOwnProperty.call(response, "error")) {
            currentOrder.value = response.order;
            isOrderReturnable();
            //currentOrderItems.value = response.items;
            response.items.forEach((item) => {
              item["is_returnable"] = false;
              item["return_resons"] = [];
              checkItemReturnable(item);
            });
            // if (
            //   currentOrder.value.customer_id !=
            //   storeX.state.customerData.entity_id
            // ) {
            //   router.push("/" + storeX.state.storeCode + "/account/orders");
            // }
          }
        })
        .catch((error) => {
          console.log(error, "error");
        });
    };

    const formateDate = (date: any) => {
      const dateArr = date.split(" ");
      return dateArr[0];
    };

    const formateQty = (qty) => {
      const finallqty = qty * 1;
      return finallqty;
    };

    const requestAction = async () => {
      if (returnData.value.returnreason_id == 0) {
        returnData.value.resonError = true;
        return;
      }
      let proceeded = 0;
      const sendItemsData = {};
      Object.keys(returnItemsData.value).forEach(async (itemId: any) => {
        const item = returnItemsData.value[itemId];
        if (item.checked) {
          if (item.return_qty == 0) {
            returnItemsData.value[itemId].qtyError = true;
            return;
          }
          if (item.return_reason == 0) {
            returnItemsData.value[itemId].resonError = true;
            return;
          }
          sendItemsData[itemId] = {
            return_qty: item.return_qty,
            sku: item.sku,
            return_reason: item.return_reason,
          };
        }
        proceeded++;
        if (proceeded == Object.keys(returnItemsData.value).length) {
          if (Object.keys(sendItemsData).length) {
            const loading = await createLoading(t("pleaseWait"));
            loading.present();
            httpApp
              .sendPostRequest(settingsApp.getEndpointUrl("handleRMARequest"), {
                request: returnData.value,
                items: sendItemsData,
              })
              .then(async (response: any) => {
                loading.dismiss();
                if (response.success) {
                  getOrder();
                  returnData.value.order_number = orderNo.value;
                  returnData.value.returnreason_id = 0;
                  returnData.value.resonError = false;
                  addingRequest.value = true;
                  router.push("/" + storeX.state.storeCode + "/account/orders");
                  openToast(t("account.returnRequestDone"), "success");
                } else {
                  if (te("apiMessages.codes." + response.error.code)) {
                    openToast(
                      t("apiMessages.codes." + response.error.code),
                      "danger"
                    );
                  } else {
                    openToast(response.error.message, "danger");
                  }
                }
              })
              .catch((error) => {
                loading.dismiss();
                console.log(error, "error");
              });
          }
        }
      });
    };

    const itemChecked = (event, item) => {
      if (event.target.checked) {
        returnItemsData.value[item.item_id]["checked"] = true;
      } else {
        returnItemsData.value[item.item_id] = {
          checked: false,
          return_qty: 0,
          sku: item.sku,
          return_reason: 0,
          qtyError: false,
          resonError: false,
        };
      }
    };

    function ionViewWillEnter() {
      currentOrder.value = null;
      getOrder();
    }

    return {
      t,
      storeX,
      ionViewWillEnter,
      currentOrder,
      currentOrderItems,
      orderNo,
      formateDate,
      formateQty,
      returnable,
      orderReturnResons,
      addingRequest,
      returnData,
      returnItemsData,
      itemChecked,
      requestAction,
    };
  },
  components: {
    IonPage,
    IonContent,
    IonButton,
    IonCheckbox,
    AccountNavComponent,
    MainHeader,
    MainFooter,
  },
});
