
import { defineComponent } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { useStore } from "@/store";
import sessionApp from "@/libs/session";
import settingsApp from "@/libs/settings";
import httpApp from "@/libs/http";
import cartApp from "@/libs/cart";
import compareApp from "@/libs/comparelist";
import wishlistApp from "@/libs/wishlist";
export default defineComponent({
  name: "AccountNavComponent",
  setup() {
    const { t, te } = useI18n({ useScope: "global" });
    const route = useRoute();
    const router = useRouter();
    const storeX = useStore();

    const isActive = (currentRoute: any) => {
      if (
        currentRoute == route.name ||
        (currentRoute == "support-tickets" && route.name == "support-ticket")
      ) {
        return true;
      }
      return false;
    };

    const getUrl = (path) => {
      return storeX.state.storeCode + path;
    };

    const nav = (path) => {
      router.push(path);
    };

    const logoutAction = async (event: any) => {
      event.preventDefault();
      httpApp
        .sendGetRequest(settingsApp.getEndpointUrl("logout"))
        .then(async (response: any) => {
          storeX.commit("setSellerData", null);
          storeX.commit("setCustomerData", null);
          if (response.success) {
            router.replace("/");
          } else {
            if (response.error.code == "core_customers_103") {
              sessionApp.deleteAll();
              router.replace("/");
            } else {
              te("apiMsgs." + response.error.code);
            }
          }
          cartApp.getQuoteItems();
          wishlistApp.getWishlistItems();
          compareApp.getProductsToCompare();
        })
        .catch((error) => {
          console.log(error);
        });
    };

    return {
      t,
      te,
      isActive,
      getUrl,
      storeX,
      nav,
      logoutAction,
    };
  },
});
